import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-top" }
const _hoisted_2 = { class: "flex-top" }
const _hoisted_3 = { class: "flex-justified" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "flex-top" }
const _hoisted_6 = { class: "flex-justified" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsageReasonSelector = _resolveComponent("UsageReasonSelector")!
  const _component_VTextarea = _resolveComponent("VTextarea")!
  const _component_AppRating = _resolveComponent("AppRating")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_PlainModal = _resolveComponent("PlainModal")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    ref: "form",
    onSubmit: _ctx.submitFeedback
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PlainModal, {
        id: _ctx.id,
        headless: "",
        class: "wide feedback-survey-modal"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("rbe-footer", _hoisted_6, [
            _createVNode(_component_LoadingButton, {
              ref: "skipFeedbackButton",
              class: "button-text neutral",
              type: "button",
              loading: _ctx.isSendingRequest,
              onClick: _ctx.skipSurvey
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode("\n            Skip\n          ")
              ])),
              _: 1
            }, 8, ["loading", "onClick"]),
            _cache[16] || (_cache[16] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              ref: "submitFeedbackButton",
              class: "button button-text",
              type: "submit",
              loading: _ctx.isSendingRequest,
              disabled: !_ctx.isSubmitEnabled || null
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("\n            Submit\n          ")
              ])),
              _: 1
            }, 8, ["loading", "disabled"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("rbe-feedback-survey", _hoisted_1, [
            _createElementVNode("rbe-header", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.modalTitle), 1)
              ]),
              _cache[5] || (_cache[5] = _createTextVNode()),
              _cache[6] || (_cache[6] = _createElementVNode("p", null, "\n            Please take a few moments to reflect on your experience using Studio.\n            Let us know which features you love and what we could be doing better.\n          ", -1))
            ]),
            _cache[11] || (_cache[11] = _createTextVNode()),
            _createVNode(_component_UsageReasonSelector, {
              ref: "usageReasonSelector",
              usageReason: _ctx.selectedUsageReason,
              "onUpdate:usageReason": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUsageReason) = $event)),
              differentUsageReason: _ctx.differentUsageReason,
              "onUpdate:differentUsageReason": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.differentUsageReason) = $event))
            }, null, 8, ["usageReason", "differentUsageReason"]),
            _cache[12] || (_cache[12] = _createTextVNode()),
            _createElementVNode("rbe-feedback-wrapper", _hoisted_5, [
              _cache[7] || (_cache[7] = _createElementVNode("label", null, "Feedback", -1)),
              _cache[8] || (_cache[8] = _createTextVNode()),
              _cache[9] || (_cache[9] = _createElementVNode("p", null, "\n            Please share any feedback you have about Studio. What do you love?\n            What could we improve? Are there any features you’d like to see in\n            the future?\n          ", -1)),
              _cache[10] || (_cache[10] = _createTextVNode()),
              _createVNode(_component_VTextarea, {
                ref: "textarea",
                modelValue: _ctx.text,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.text) = $event)),
                required: "",
                placeholder: "Write here..."
              }, null, 8, ["modelValue"])
            ]),
            _cache[13] || (_cache[13] = _createTextVNode()),
            _createVNode(_component_AppRating, {
              ref: "appRating",
              rating: _ctx.selectedRating,
              "onUpdate:rating": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedRating) = $event)),
              lowRatingExplanation: _ctx.lowRatingExplanation,
              "onUpdate:lowRatingExplanation": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.lowRatingExplanation) = $event))
            }, null, 8, ["rating", "lowRatingExplanation"])
          ]),
          _cache[17] || (_cache[17] = _createTextVNode())
        ]),
        _: 1
      }, 8, ["id"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}