import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import {Doc} from 'sharedb/lib/client';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {IUserBookSettings} from '@reedsy/reedsy-sharedb/lib/common/user-book-settings/user-book-settings';
import {memoize} from '@reedsy/utils.object';
import {clone} from '@reedsy/utils.clone';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import BookshelfStoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {getUserBookId} from '@reedsy/reedsy-sharedb/lib/utils/get-user-book-id';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

@injectable()
export class UserBookSettingsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    User: SharedUserModule,

    @$inject('StoreModule')
    @named(BookshelfStoreName.ShareDb)
    ShareDB: ShareDBModule,
  ) {
    @Module({name: BookshelfStoreName.UserBookSettings, store})
    class UserBookSettings extends VuexModule {
      public data: {[docId: string]: IUserBookSettings} = {};

      public get forBook() {
        return (bookId: string): IUserBookSettings => {
          const docId = getUserBookId({
            userId: User.id,
            bookId,
          });
          return this.data[docId] || null;
        };
      }

      @Mutation
      public DATA({id, data}: IDataMutation<'userBookSettings'>): void {
        const clonedData = clone(data);
        clonedData._id = id;
        this.data[id] = clonedData;
      }

      @Mutation
      public APPLY_OPS({id, ops}: IApplyOpsMutation<'userBookSettings'>): void {
        ops.forEach((op) => applyOp(this.data[id], op));
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        const query = await ShareDB.subscribeQuery({
          collection: 'userBookSettings',
        });

        const subscribeDocs = (docs: Doc<IUserBookSettings>[]): void => {
          docs.forEach((doc) => subscribeVuexToDoc(doc, this));
        };

        subscribeDocs(query.results);
        query.on('insert', subscribeDocs);
        query.on('remove', (removedDocs) => {
          removedDocs.forEach((doc) => {
            const goalId = doc.id;
            this.REMOVE_BOOK_WORD_GOAL(goalId);
            doc.destroy();
          });
        });
      }

      @Mutation
      private REMOVE_BOOK_WORD_GOAL(docId: string): void {
        delete this.data[docId];
      }
    }

    this.Module = UserBookSettings;
  }
}

export type UserBookSettingsModule = InstanceType<UserBookSettingsModuleFactory['Module']>;
