<template>
  <rbe-bookshelf-page-filter
    class="themed"
    :class="{
      'dark-theme': $layout.contentIsDark,
      'light-theme': !$layout.contentIsDark
    }"
  >
    <VuiPageFilters
      ref="pageFilter"
      :count="bookCount"
      :toggles="toggles"
      items-plural="books"
      items-singular="book"
      search-placeholder="Title..."
      :search="$bookshelf.searchValue"
      :is-loading="$layout.isLoadingBooksList"
      class="bookshelf-books-page-filter"
      @update:search="updateSearchValue"
      @toggle="changeArchiveRoute"
    />
  </rbe-bookshelf-page-filter>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

@Component({})
export default class BooksPageFilter extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  public get isArchivedBooks(): boolean {
    return this.$route.name === BookshelfRouteName.ArchivedBooks;
  }

  public get toggles(): any[] {
    return [
      {filter: 'stateSearch', isActive: this.isArchivedBooks, label: 'View archive', value: 'archived'},
    ];
  }

  public get bookCount(): number {
    return this.$bookshelf.bookshelfEntries.length;
  }

  public updateSearchValue(value: string): void {
    this.$bookshelf.search(value);
  }

  public changeArchiveRoute(): void {
    if (this.isArchivedBooks) {
      this.$router.push({name: BookshelfRouteName.BooksList});
    } else {
      this.$router.push({name: BookshelfRouteName.ArchivedBooks});
    }
  }
}
</script>

<style lang="scss">
rbe-bookshelf-page-filter .bookshelf-books-page-filter.vui-page-filters {
  .vui-list-filters-toggle .vui-icon {
    #icon-fill {
      @include transition(fill);

      fill: var(--TEXT-INVERTED-COLOR);
    }
  }

  .search-toggle-button, div.main-filters {
    @include transition(background-color);

    a {
      @include transition((color, background-color));
    }
  }

  .search-toggle-button .vui-icon {
    color: var(--text-color);
  }

  .main-filters {
    z-index: $z-index-top-bar;
  }

  .mobile-search {
    position: absolute;
    width: calc(100% - $scrollbar-width);
    z-index: $z-index-action-bar;
    background-color: var(--bookshelf-layout-background);
    border-bottom: $border;
  }

  @include screen-less-than($bookshelf-color-breakpoint) {
    border-top: $border;
    border-bottom: $border;

    input[type='text'] {
      color: var(--text-color);
    }

    .search-toggle-button {
      background-color: var(--bookshelf-page-filter-search-button-background);

      &.active {
        background-color: var(--bookshelf-page-filter-search-button-background-active);
      }
    }

    .mobile-search {
      .button-icon {
        @include vuiButtonsMakeColoredButton($translucent, map.get($white, base));
      }
    }

    div.main-filters {
      background-color: var(--bookshelf-layout-background);

      .toggles {
        border-left: $border;

        a {
          color: var(--text-color);

          &.active {
            background-color: var(--NEUTRAL-COLOR);
          }
        }
      }

      .search::before {
        border-left: $border;
      }
    }
  }
}
</style>
