<template>
  <Panel
    title="Invite collaborators"
    class="invite-collaborators-panel"
  >
    <VForm
      class="flex-top invite-form"
      @submit.prevent="sendInvitationsToCurrentBook"
    >
      <p>
        Invite others to work on your book alongside you.
        Track changes and work together in real-time with our collaborative features.
      </p>

      <UsersSelector
        v-model="inviteesEmails"
        :failed-emails="failedEmails"
        :disabled="isSendingRequest"
        hide-errors
        class="users-selector"
      />
      <ErrorMessageBox
        v-if="hasUnknownError"
        with-icon
      >
        Could not send the invitation, please try again later.
        If the error persists, contact our support team.
      </ErrorMessageBox>
      <rbe-footer-content class="flex-justified">
        <p class="bottom-text">
          Recipients will be asked to create or log in with a Reedsy account.
        </p>
        <LoadingButton
          ref="replaceAll"
          class="button-text button-with-icon invite-button"
          type="submit"
          :loading="isSendingRequest"
          :disabled="!inviteesEmails.length"
        >
          <span>Invite</span><VuiIconEnvelope />
        </LoadingButton>
      </rbe-footer-content>
    </VForm>
  </Panel>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {InviteCollaboratorMixin} from '@reedsy/studio.shared/mixins/collaboration/invite-collaborator-mixin';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import Navigation from '@reedsy/studio.shared/services/navigation';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import ErrorMessageBox from '@reedsy/studio.shared/components/error-message-box/error-message-box.vue';
import UsersSelector from '@reedsy/studio.shared/components/users-selector/users-selector.vue';

@Component({
  components: {
    Panel,
    LoadingButton,
    ErrorMessageBox,
    UsersSelector,
  },
})
export default class InviteCollaboratorsPanel extends mixins(InviteCollaboratorMixin, BookshelfVue) {
  @$lazyInject('Navigation')
  public readonly navigation: Navigation;

  @$lazyInjectStore(StoreName.CurrentBook)
  public readonly book: CurrentBookModule;

  public async sendInvitationsToCurrentBook(): Promise<void> {
    await this.sendInvitations(this.book.id);
  }
}
</script>

<style lang="scss" scoped>
.invite-form {
  gap: $space-base;
}

.bottom-text {
  font-size: $font-size-sm;
  color: var(--NEUTRAL-COLOR-strongest);
}

.invite-button :deep(.vui-icon) {
  vertical-align: bottom;
}
</style>
