<template>
  <PlainModal
    :id="id"
    class="wide help-modal"
    title="Need some help?"
  >
    <rbe-description-wrapper>
      <p>Check the FAQ or chat with a member of the Reedsy Team.</p>
    </rbe-description-wrapper>
    <rbe-help-options>
      <a
        class="help-option"
        :href="faqLink"
        target="_blank"
        @click="closeModal"
      >
        <InfoIcon class="icon" />
        <span class="option-title">
          Visit the FAQ
        </span>
      </a>

      <button
        type="button"
        class="help-option open-crisp-option"
        @click="openCrisp"
      >
        <RicardoIcon class="icon" />
        <span class="option-title">
          Chat with our team
        </span>
      </button>
    </rbe-help-options>
  </PlainModal>
</template>

<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {config} from '@reedsy/studio.shared/config';
import RicardoIcon from './ricardo-icon.vue';
import InfoIcon from './info-icon.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {ICrispManager} from '@reedsy/studio.home.bookshelf/utils/crisp/i-crisp-manager';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@Component({
  components: {
    PlainModal,
    InfoIcon,
    RicardoIcon,
  },
})
export default class HelpModal extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInject('CrispManager')
  public readonly $crispManager: ICrispManager;

  @$lazyInjectStore(StoreName.Modals)
  public readonly $modals: BookshelfModalsModule;

  public readonly cancelable = true;

  public get faqLink(): string {
    return config.reedsyApps.faq.url;
  }

  public openCrisp(): void {
    this.$crispManager.openChat();
    this.closeModal();
  }

  public closeModal(): void {
    this.$modals.CLOSE(this.id);
  }
}
</script>

<style lang="scss" scoped>
.help-modal {
  rbe-description-wrapper {
    display: flex;
    justify-content: center;
  }

  rbe-help-options {
    display: flex;
    justify-content: space-between;
    margin-top: $space-md;
    flex-wrap: wrap;

    .help-option {
      cursor: pointer;
      flex: 1;
      margin: $space-sm;
      border: $border-lg!important;
      border-radius: $border-radius-lg!important;
      height: 12rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 15rem;

      &:hover {
        border-color: var(--SECONDARY-ACCENT-COLOR)!important;
      }

      .icon {
        @include size(6rem);
      }

      .option-title {
        margin-top: $space-md;

        @include font-family($controls, bold);
      }
    }
  }
}
</style>
