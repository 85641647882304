import {VuexModule, Mutation, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {Store} from 'vuex';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import ColorPool from '@reedsy/studio.shared/services/color-pool/color-pool';
import {dig} from '@reedsy/utils.dig';
import {memoize} from '@reedsy/utils.object';
import {IDisplayableAnalyticsEvents} from '@reedsy/studio.isomorphic/utils/i-displayable-analytics-events';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';

const logger = loggerFactory.create('SharedUserModuleFactory');

@injectable()
export class SharedUserModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: SharedStoreName.User, store})
    class SharedUser extends VuexModule {
      public info: IUserInfo = null;
      public events: IDisplayableAnalyticsEvents = {};

      public get loaded(): boolean {
        return !!this.info;
      }

      public get id(): string {
        return dig(this, 'info', '_id');
      }

      public get uuid(): string {
        return dig(this, 'info', 'uuid');
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        const info = await api.getCurrentUser();
        this.tryGettingUserEvents();
        info.color = ColorPool.CURRENT_USER_COLOR;
        this.INFO(info);
      }

      @Action
      private async tryGettingUserEvents(): Promise<void> {
        try {
          const userEvents = await api.fetchUserAnalyticsEvents();
          this.EVENTS(userEvents);
        } catch (error) {
          logger.debug('Cannot load user events', {error});
        }
      }

      @Mutation
      private INFO(info: IUserInfo): void {
        this.info = info;
      }

      @Mutation
      private EVENTS(events: IDisplayableAnalyticsEvents): void {
        this.events = events;
      }
    }

    this.Module = SharedUser;
  }
}

export type SharedUserModule = InstanceType<SharedUserModuleFactory['Module']>;
