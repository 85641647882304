import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/inlined/open-book.svg'


const _hoisted_1 = { class: "empty-list-placeholder" }
const _hoisted_2 = { class: "under-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconPlus = _resolveComponent("VuiIconPlus")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("img", {
      src: _imports_0,
      alt: "no exports"
    }, null, -1)),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "title" }, "\n        No exports yet.\n      ", -1)),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Your export will appear here.", -1)),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createVNode(_component_router_link, {
        ref: "new-export-button",
        to: _ctx.bookExportSubmissionRoute,
        class: "button button-text button-accent new-export-button"
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createTextVNode("\n        New export\n        ")),
          _createVNode(_component_VuiIconPlus, { class: "icon-sm new-export-icon" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}