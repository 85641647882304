import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InviteCollaboratorsPanel = _resolveComponent("InviteCollaboratorsPanel")!
  const _component_ManageAccessPanel = _resolveComponent("ManageAccessPanel")!
  const _component_InfoPanel = _resolveComponent("InfoPanel")!
  const _component_LayoutWithRightSideBar = _resolveComponent("LayoutWithRightSideBar")!

  return (_openBlock(), _createElementBlock("rbe-book-access-content", null, [
    _createVNode(_component_LayoutWithRightSideBar, null, {
      "left-side": _withCtx(() => [
        _createElementVNode("rbe-left-side", null, [
          _createVNode(_component_InviteCollaboratorsPanel, { class: "hidden-until-sm" }),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _createVNode(_component_ManageAccessPanel)
        ])
      ]),
      "right-side": _withCtx(() => [
        _createVNode(_component_InfoPanel, {
          class: "flat permissions-panel",
          title: "Permissions"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("p", null, "\n            Collaborators are currently given access to edit, update and export your entire book.\n            You can manage who has access from this page at any time.\n          ", -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createElementVNode("rbe-fixed-footer", null, [
      _createElementVNode("button", {
        class: "button-text reedsy-accented",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openInviteCollaboratorModal && _ctx.openInviteCollaboratorModal(...args)))
      }, "\n        Invite\n      ")
    ])
  ]))
}