import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/inlined/book-cover-placeholder.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: _imports_0
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_VuiProgressBar = _resolveComponent("VuiProgressBar")!
  const _component_VuiIconUpload = _resolveComponent("VuiIconUpload")!

  return (_openBlock(), _createElementBlock("rbe-image-uploader", {
    class: _normalizeClass([{
      'dropping': _ctx.isDropping,
      'uploading': _ctx.isUploading,
    }, "panel"])
  }, [
    (_ctx.isUploading)
      ? (_openBlock(), _createElementBlock("rbe-upload-progress-bar", _hoisted_1, [
          (_ctx.uploadProgress === null)
            ? (_openBlock(), _createBlock(_component_VuiLoadingIndicator, {
                key: 0,
                class: "lg"
              }))
            : (_openBlock(), _createBlock(_component_VuiProgressBar, {
                key: 1,
                class: "sm",
                percentage: _ctx.uploadProgress
              }, null, 8, ["percentage"]))
        ]))
      : _createCommentVNode("", true),
    _cache[8] || (_cache[8] = _createTextVNode()),
    _createElementVNode("rbe-uploader-content", null, [
      (_ctx.hasImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.url
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("img", _hoisted_3)),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _cache[5] || (_cache[5] = _createElementVNode("h1", null, "\n        Upload a book cover\n      ", -1)),
      _cache[6] || (_cache[6] = _createTextVNode()),
      _createElementVNode("button", {
        class: "button-text button-accent button-with-icon",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pickFile && _ctx.pickFile(...args)))
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "Upload a file", -1)),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createVNode(_component_VuiIconUpload, { class: "icon-black" })
      ]),
      _cache[7] || (_cache[7] = _createTextVNode()),
      (_ctx.hasImage)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            type: "button",
            class: "remove-file link",
            title: "Remove cover",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args)))
          }, "\n        Remove cover\n      "))
        : _createCommentVNode("", true)
    ]),
    _cache[9] || (_cache[9] = _createTextVNode()),
    ((!_ctx.isUploading && _ctx.isDropping))
      ? (_openBlock(), _createElementBlock("rbe-drop-indicator", _hoisted_4))
      : _createCommentVNode("", true),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _createElementVNode("input", {
      ref: "fileInput",
      accept: _ctx.acceptedFileTypes,
      type: "file"
    }, null, 8, _hoisted_5)
  ], 2))
}