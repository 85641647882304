import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "button button-text button-with-icon button-accent download-export-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconDownload = _resolveComponent("VuiIconDownload")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!

  return (_openBlock(), _createElementBlock("rbe-export-action", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.isDownloadable)
          ? (_openBlock(), _createElementBlock("rbe-download-button", _hoisted_1, [
              _cache[0] || (_cache[0] = _createTextVNode("\n        Download\n        ")),
              _createVNode(_component_VuiIconDownload, { class: "export-action-right-asset" })
            ]))
          : (_openBlock(), _createBlock(_component_LoadingButton, {
              key: 1,
              type: "button",
              class: "button button-text neutral cancel-export-button",
              loading: _ctx.isCancelling,
              onClick: _ctx.cancelExport
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("\n        Cancel\n      ")
              ])),
              _: 1
            }, 8, ["loading", "onClick"]))
      ]),
      _: 1
    })
  ]))
}