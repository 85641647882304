import tinycolor from 'tinycolor2';
import SASS from '@reedsy/studio.shared/utils/sass';

export default class ColorPool {
  public static readonly COLORS = Object.freeze(
    Array(SASS.userColorPoolSize)
      .fill(null)
      .map((_, i) => `var(--studio-userColorPool-${i})`),
  );

  public static readonly CURRENT_USER_COLOR = 'var(--studio-userColorPool-currentUser)';

  private availableColors = ColorPool.COLORS.slice(0);
  private takenColors = new Set<string>();

  public get(): string {
    const color = this.availableColors.shift() || this.randomColor();
    this.takenColors.add(color.toUpperCase());
    return color;
  }

  private randomColor(): string {
    let color: string;
    while (!this.isReadable(color)) {
      color = tinycolor.random().toHexString().toUpperCase();
      if (this.takenColors.has(color)) color = null;
    }

    return color;
  }

  private isReadable(color: string): boolean {
    if (!color) return false;
    // TODO: Check if we need to observe this value updates in dark theme
    const foreColor = window.getComputedStyle(document.documentElement).getPropertyValue('--TEXT-BACKGROUND');
    const readable = tinycolor.isReadable(foreColor, color);
    return readable;
  }
}
