import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button button-lg button-icon button-accent" }
const _hoisted_2 = { class: "button-lg button-icon button-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconPlus = _resolveComponent("VuiIconPlus")!
  const _component_VuiIconCloud = _resolveComponent("VuiIconCloud")!

  return (_openBlock(), _createElementBlock("rbe-book-creation-buttons", null, [
    _createElementVNode("button", {
      class: "button-text create-book-button flat",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNewBookModal && _ctx.openNewBookModal(...args)))
    }, [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_VuiIconPlus, { class: "icon-lg" })
      ]),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text" }, "\n        Create book\n      ", -1))
    ]),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
    _cache[8] || (_cache[8] = _createTextVNode()),
    _createElementVNode("button", {
      class: "button-text import-book-button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openImportBookModal && _ctx.openImportBookModal(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_VuiIconCloud, { class: "icon-lg" })
      ]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text" }, "\n        Import book\n      ", -1))
    ])
  ]))
}