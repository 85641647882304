import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Leave book",
    class: "leave-book-panel"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", { ref: "text" }, "\n      This book belongs to " + _toDisplayString(_ctx.bookOwnerName) + ".\n      Clicking the button below will remove the book from your bookshelf,\n      and you will lose access to it.\n    ", 513),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("button", {
        ref: "leaveBookButton",
        type: "button",
        class: "delete-book-button button-text reedsy-accented danger bordered",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openConfirmationModal && _ctx.openConfirmationModal(...args)))
      }, "\n      Leave book\n    ", 512)
    ]),
    _: 1
  }))
}