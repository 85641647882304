<template>
  <Panel class="accept-invitation-panel">
    <rbe-panel-content>
      <Logo
        text-only
        with-app-name
      />
      <hr>
      <rbe-loading-indicator-wrapper
        v-if="isLoading"
        class="flex-center"
      >
        <VuiLoadingIndicator />
      </rbe-loading-indicator-wrapper>
      <template v-else>
        <rbe-invitation-info class="flex-justified">
          <rbe-user-avatar>
            <Avatar
              :user-info="$user.info"
              bordered
              class="xl"
            />
          </rbe-user-avatar>
          <rbe-text-wrapper class="flex-top">
            <h1>You’ve been granted access to <span class="book-owner-name">{{ bookOwnerName }}</span>’s book</h1>
            <p>
              Accept the invitation to access
              ‘<span class="book-title">{{ bookTitle }}</span>’
              using the Reedsy Account registered under
              <a
                :href="`mailto:${currentUserEmail}`"
                class="link reversed user-email"
              >{{ currentUserEmail }}</a>.
            </p>
          </rbe-text-wrapper>
        </rbe-invitation-info>
        <hr>
        <rbe-footer class="flex-justified">
          <button
            class="change-account-button"
            @click="$invitationApprovalFlow.changeAccount"
          >
            <span class="link">Sign in with a different account</span>
          </button>
          <button
            class="button-text reedsy-accented accept-invitation-button"
            @click="$invitationApprovalFlow.acceptInvitation"
          >
            Accept invitation
          </button>
        </rbe-footer>
      </template>
    </rbe-panel-content>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import Logo from '@reedsy/studio.shared/components/logo/logo.vue';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {InvitationApprovalFlowModule} from '@reedsy/studio.home.bookshelf/store/modules/invitation-approval-flow';

@Component({
  components: {
    Panel,
    Logo,
    Avatar,
  },
})
export default class AcceptInvitationPanel extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInjectStore(StoreName.InvitationApprovalFlow)
  public $invitationApprovalFlow: InvitationApprovalFlowModule;

  public get isLoading(): boolean {
    return this.$invitationApprovalFlow.isLoading;
  }

  public get currentUserEmail(): string {
    return this.$user.info.email;
  }

  public get bookTitle(): string {
    return this.$invitationApprovalFlow.invitationData.book.title;
  }

  public get bookOwnerName(): string {
    return this.$invitationApprovalFlow.invitationData.bookOwner.name;
  }
}
</script>

<style lang="scss" scoped>
.accept-invitation-panel {
  width: 46rem;
  max-width: 100vw;
  box-sizing: border-box;
}

rbe-loading-indicator-wrapper {
  width: 100%;
}

hr {
  width: 100%;
  height: $border-width-sm;
  background-color: var(--reedsy-dividerColor);
  box-sizing: border-box;
  margin: $space-base 0;
}

rbe-invitation-info {
  gap: $space-base;

  h1 {
    font-size: $font-size-lg;
    margin-bottom: $space-xs;
  }

  .book-title {
    font-style: italic;
  }
}

@include screen-less-than(sm) {
  .accept-invitation-panel{
    height: safe-vh(100);
  }

  :deep(.panel-body), rbe-panel-content {
    height: 100%;
  }

  rbe-panel-content {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  rbe-invitation-info {
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logo {
    height: auto;
  }

  rbe-text-wrapper {
    text-align: center;
  }

  rbe-loading-indicator-wrapper {
    margin-top: auto;
    margin-bottom: auto;
  }

  rbe-footer {
    flex-direction: column-reverse;

    .accept-invitation-button {
      margin-bottom: $space-base;
      width: 100%;
    }
  }
}
</style>
