import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "users-selector" }
const _hoisted_2 = { class: "add-someone-input-group" }
const _hoisted_3 = { class: "flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VInput = _resolveComponent("VInput")!
  const _component_VForm = _resolveComponent("VForm")!
  const _component_Invitee = _resolveComponent("Invitee")!
  const _component_ErrorMessageBox = _resolveComponent("ErrorMessageBox")!

  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    _createVNode(_component_VForm, {
      ref: "form",
      class: "users-selector",
      onSubmit: _withModifiers(_ctx.addEmail, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("label", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode("\n        Add someone\n        \n        ")),
          _createVNode(_component_VInput, {
            is: "email-input",
            ref: "input",
            modelValue: _ctx.inputText,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
            class: "input-full",
            placeholder: "Enter an email address...",
            "custom-validators": _ctx.customValidators
          }, {
            right: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("button", { class: "button-text button-accent button-sm add-invitee-button" }, "\n              Add\n            ", -1)
            ])),
            _: 1
          }, 8, ["modelValue", "custom-validators"])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"]),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _createElementVNode("rbe-invitees-list", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emails, (email) => {
        return (_openBlock(), _createBlock(_component_Invitee, {
          key: email,
          email: email,
          "error-code": _ctx.emailErrorCode(email),
          onRemove: _ctx.removeEmail
        }, null, 8, ["email", "error-code", "onRemove"]))
      }), 128))
    ]),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createElementVNode("input", {
      ref: _ctx.inputFieldRef,
      type: "hidden"
    }, null, 512),
    _cache[5] || (_cache[5] = _createTextVNode()),
    (_ctx.validationError.hasError)
      ? (_openBlock(), _createBlock(_component_ErrorMessageBox, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.validationError.message), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}