<template>
  <MainLayout>
    <template #above-content>
      <rbe-overflow-background />
    </template>

    <rbe-content class="overflow-content">
      <rbe-settings-panel class="panel">
        <rbe-menu-title>
          <VuiIconSettings class="icon-xl" />
          <p>Settings</p>
        </rbe-menu-title>
        <AccountSettingsMenu
          ref="accountSettingsMenu"
          @open-details="isMobileSettingsShown = true"
        />
        <rbe-settings-wrapper
          :class="{
            'mobile-hidden': !isMobileSettingsShown
          }"
        >
          <rbe-settings-title>
            <button
              ref="closeMobileSetting"
              class="close-mobile-setting button-icon reedsy-accented inverted"
              @click="isMobileSettingsShown = false"
            >
              <VuiIconArrowLeft class="icon-white" />
            </button>
            <p ref="settingTitle">
              {{ settingTitle }}
            </p>
          </rbe-settings-title>
          <rbe-settings-content>
            <RouterView />
          </rbe-settings-content>
        </rbe-settings-wrapper>
      </rbe-settings-panel>
    </rbe-content>
  </MainLayout>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import AccountSettingsMenu from '@reedsy/studio.home.bookshelf/components/account-settings-menu/account-settings-menu.vue';
import {ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING} from '@reedsy/studio.home.bookshelf/utils/account-settings-route-name-mapping';

@Component({
  components: {
    MainLayout,
    AccountSettingsMenu,
  },
})
export default class AccountSettings extends BookshelfVue {
  public isMobileSettingsShown = true;

  public get settingTitle(): string {
    const routeName = this.$route.name as string;
    return ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING[routeName];
  }
}
</script>

<style lang="scss" scoped>
rbe-overflow-background  {
  padding-top: $space-md + $account-settings-top-overflow;
  width: 100%;
  background-color: var(--bookshelf-layout-background);
}

rbe-settings-panel {
  padding: 0;
  display: grid;
  margin-top: -$account-settings-top-overflow;
  column-gap: 1.5rem;
  min-height: safe-vh(70);
  grid-template:
    'menu-title settings-content' $account-settings-top-overflow
    'menu settings-content' auto
    / minmax($account-settings-min-menu-width, 1fr) 3fr;

  @include screen-less-than(sm) {
    min-height: 1rem;
    padding-bottom: $space-base;
    grid-template:
      'menu-title' $account-settings-top-overflow
      'menu' auto
      'settings-content' auto
      / 1fr;
  }
}

rbe-menu-title, rbe-settings-title {
  display: flex;
  align-items: center;
  font-size: $font-size-lg;
  border-bottom: $border;

  @include font-family($controls, bold);
}

rbe-menu-title, rbe-account-settings-menu {
  border-right: $border-lg;

  @include screen-less-than(sm) {
    border-right: none;
  }
}

rbe-menu-title {
  grid-area: menu-title;
  gap: 1rem;
  padding-left: $bookshelf-desktop-overflow;
}

rbe-account-settings-menu {
  grid-area: menu;
}

rbe-settings-wrapper {
  grid-area: settings-content;
  padding-right: $bookshelf-desktop-overflow;
  box-sizing: border-box;

  @include screen-less-than(sm) {
    z-index: $z-index-modals;
    position: fixed;
    height: safe-vh(100);
    width: 100vw;
    top: 0;
    right: 0;
    background-color: var(--reedsy-plain);
    padding-left: $bookshelf-mobile-overflow;
    padding-right: $bookshelf-mobile-overflow;
    overflow-y: auto;

    @include transition(right);

    &.mobile-hidden {
      right: calc(-100vw - (2 * $bookshelf-mobile-overflow));
    }
  }
}

rbe-settings-title {
  gap: $space-base;
  height: $account-settings-top-overflow;
  box-sizing: border-box;

  .close-mobile-setting {
    display: none;

    @include screen-less-than(sm) {
      display: flex;
    }
  }
}

rbe-settings-content {
  padding-top: $space-xl;
  padding-bottom: $space-xl;
}
</style>
