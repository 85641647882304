<template>
  <button
    class="context-menu-button button-text reedsy-accented neutral bordered button-round button-xs"
  >
    <VuiIconEllipsis class="icon-sm" />
  </button>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ContextMenuButton extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
.context-menu-button.reedsy-accented.bordered.neutral {
  background-color: var(--reedsy-plain);
}
</style>
