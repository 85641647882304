import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button-text button-xs reedsy-accented neutral button-icon bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconInfo = _resolveComponent("VuiIconInfo")!
  const _component_VDropdown = _resolveComponent("VDropdown")!

  return (_openBlock(), _createBlock(_component_VDropdown, _mergeProps({
    class: "info-tip",
    boundary: _ctx.tooltipBoundary
  }, _ctx.$attrs), {
    popper: _withCtx(() => [
      _createElementVNode("rbe-info-tip-content", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "trigger", {}, () => [
        _createElementVNode("button", _hoisted_1, [
          _createVNode(_component_VuiIconInfo, { class: "icon-sm" })
        ])
      ]),
      _cache[0] || (_cache[0] = _createTextVNode())
    ]),
    _: 3
  }, 16, ["boundary"]))
}