import {VuexModule, Mutation, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {uniqueId} from '@reedsy/studio.shared/utils/unique-id';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {Store} from 'vuex';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';

@injectable()
export class SharedSavesModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(SharedStoreName.Modals)
    Modals: SharedModalsModule,
  ) {
    @Module({name: SharedStoreName.Saves, store})
    class SharedSaves extends VuexModule {
      public tooltipShown = false;

      private readonly inProgressSaves: {[id: string]: boolean} = {};
      private errorCount = 0;

      public get isSaving(): boolean {
        return !!Object.keys(this.inProgressSaves).length;
      }

      public get hasErrors(): boolean {
        return !!this.errorCount;
      }

      @Mutation
      public START_SAVING(id: string): void {
        this.inProgressSaves[id] = true;
      }

      @Mutation
      public DONE_SAVING({id, error}: IDoneSavingMutation): void {
        if (!this.inProgressSaves[id]) return;
        if (error) {
          this.errorCount++;
          Modals.OPEN('Desynchronisation');
        }
        delete this.inProgressSaves[id];
      }

      @Mutation
      public SHOW_TOOLTIP(show: boolean): void {
        this.tooltipShown = show;
      }

      @Action
      public async startSaving(): Promise<DoneSaving> {
        const id = uniqueId();
        this.START_SAVING(id);
        return (error?: Error) => this.DONE_SAVING({id, error});
      }
    }

    this.Module = SharedSaves;
  }
}

export type SharedSavesModule = InstanceType<SharedSavesModuleFactory['Module']>;

export type DoneSaving = (error?: Error) => void;
interface IDoneSavingMutation {
  id: string; error?: Error;
}
