import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "button button-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconArrowLeft = _resolveComponent("VuiIconArrowLeft")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.bookshelfRoute,
    class: "book-back-button-link"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_VuiIconArrowLeft)
      ]),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "back-text hidden-until-md" }, "\n      Back\n    ", -1))
    ]),
    _: 1
  }, 8, ["to"]))
}