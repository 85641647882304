import { openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-export-theme-preview", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("img", {
          key: _ctx.relativeImagePath,
          src: _ctx.themeImage
        }, null, 8, _hoisted_1))
      ]),
      _: 1
    })
  ]))
}