import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_NoBookCoverPlaceholder = _resolveComponent("NoBookCoverPlaceholder")!
  const _component_EntryAction = _resolveComponent("EntryAction")!
  const _component_BookshelfEntry = _resolveComponent("BookshelfEntry")!

  return (_openBlock(), _createBlock(_component_BookshelfEntry, null, {
    "bottom-hover": _withCtx(() => [
      _createVNode(_component_EntryAction, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            type: "button",
            class: "button-text cancel-button cancel-import-button",
            "aria-label": `Cancel import of book with title ${_ctx.bookTitle}`,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelImport && _ctx.cancelImport(...args)))
          }, "\n          Cancel\n        ", 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("rbe-book-import", null, [
        _createVNode(_component_NoBookCoverPlaceholder, {
          title: _ctx.bookTitle,
          author: _ctx.$user.info,
          class: "no-book-cover-placeholder"
        }, {
          default: _withCtx(() => [
            _createElementVNode("rbe-book-cover-loader", null, [
              _createVNode(_component_VuiLoadingIndicator, { class: "lg" }),
              _cache[1] || (_cache[1] = _createTextVNode()),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "Importing...", -1))
            ])
          ]),
          _: 1
        }, 8, ["title", "author"])
      ]),
      _cache[3] || (_cache[3] = _createTextVNode())
    ]),
    _: 1
  }))
}