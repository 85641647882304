import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconSettings = _resolveComponent("VuiIconSettings")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VuiIconArrowRight = _resolveComponent("VuiIconArrowRight")!
  const _component_EntryAction = _resolveComponent("EntryAction")!

  return (_openBlock(), _createBlock(_component_EntryAction, { class: "entry-action" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        ref: "manageButton",
        class: "button button-text button-md reedsy-accented inverted manage-button",
        to: _ctx.bookLocation,
        "aria-label": `Manage book ${_ctx.book.title}`
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createTextVNode("\n      Manage\n      ")),
          _createVNode(_component_VuiIconSettings)
        ]),
        _: 1
      }, 8, ["to", "aria-label"]),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createElementVNode("a", {
        class: "button button-text button-md full-width reedsy-accented open-in-editor-button",
        href: _ctx.bookEditorLink,
        target: "_blank",
        "aria-label": `Continue writing ${_ctx.book.title}`
      }, [
        _cache[1] || (_cache[1] = _createTextVNode("\n      Write\n      ")),
        _createVNode(_component_VuiIconArrowRight)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}