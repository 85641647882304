<template>
  <rbe-book-access-content>
    <LayoutWithRightSideBar>
      <template #left-side>
        <rbe-left-side>
          <InviteCollaboratorsPanel class="hidden-until-sm" />
          <ManageAccessPanel />
        </rbe-left-side>
      </template>
      <template #right-side>
        <InfoPanel
          class="flat permissions-panel"
          title="Permissions"
        >
          <p>
            Collaborators are currently given access to edit, update and export your entire book.
            You can manage who has access from this page at any time.
          </p>
        </InfoPanel>
      </template>
    </LayoutWithRightSideBar>
    <rbe-fixed-footer>
      <button
        class="button-text reedsy-accented"
        type="button"
        @click="openInviteCollaboratorModal"
      >
        Invite
      </button>
    </rbe-fixed-footer>
  </rbe-book-access-content>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import InviteCollaboratorsPanel from '@reedsy/studio.home.bookshelf/components/invite-collaborators-panel/invite-collaborators-panel.vue';
import ManageAccessPanel from '@reedsy/studio.home.bookshelf/components/manage-access-panel/manage-access-panel.vue';
import LayoutWithRightSideBar from '@reedsy/studio.home.bookshelf/components/layout-with-right-side-bar/layout-with-right-side-bar.vue';
import InfoPanel from '@reedsy/studio.home.bookshelf/components/info-panel/info-panel.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';

@Component({
  components: {
    InviteCollaboratorsPanel,
    ManageAccessPanel,
    LayoutWithRightSideBar,
    InfoPanel,
  },
})
export default class BookAccess extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public openInviteCollaboratorModal(): void {
    this.$modals.OPEN({
      type: 'InviteCollaborator',
      context: {
        book: {
          id: this.$currentBook.id,
          uuid: this.$currentBook.uuid,
        },
      },
    });
  }
}
</script>

<style lang="scss" scoped>
$space-between-panels: $space-md;

.invite-collaborators-panel {
  margin-bottom: $space-between-panels;
}

rbe-book-access-content {
  $button-height: 2.5rem;
  $footer-height: 2 * $space-base + $border-width + $button-height;

  padding-bottom: $footer-height + $space-md;
}

rbe-fixed-footer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  box-shadow: $box-shadow-base;
  background-color: var(--bookshelf-layout-inverted-background);
  box-sizing: border-box;
  padding: $space-base;
  border-top: $border;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;

  button {
    width: 100%;
  }
}

@include screen-less-than(md) {
  .permissions-panel {
    margin-top: $space-between-panels;
  }
}

@include screen-more-than(xs) {
  rbe-book-access-content {
    padding-bottom: $space-md;
  }

  rbe-fixed-footer {
    display: none;
  }
}
</style>
