import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex-justified" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersSelector = _resolveComponent("UsersSelector")!
  const _component_ErrorMessageBox = _resolveComponent("ErrorMessageBox")!
  const _component_VuiIconEnvelope = _resolveComponent("VuiIconEnvelope")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_VForm = _resolveComponent("VForm")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Invite collaborators",
    class: "invite-collaborators-panel"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VForm, {
        class: "flex-top invite-form",
        onSubmit: _withModifiers(_ctx.sendInvitationsToCurrentBook, ["prevent"])
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "\n        Invite others to work on your book alongside you.\n        Track changes and work together in real-time with our collaborative features.\n      ", -1)),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createVNode(_component_UsersSelector, {
            modelValue: _ctx.inviteesEmails,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inviteesEmails) = $event)),
            "failed-emails": _ctx.failedEmails,
            disabled: _ctx.isSendingRequest,
            "hide-errors": "",
            class: "users-selector"
          }, null, 8, ["modelValue", "failed-emails", "disabled"]),
          _cache[7] || (_cache[7] = _createTextVNode()),
          (_ctx.hasUnknownError)
            ? (_openBlock(), _createBlock(_component_ErrorMessageBox, {
                key: 0,
                "with-icon": ""
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("\n        Could not send the invitation, please try again later.\n        If the error persists, contact our support team.\n      ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _cache[8] || (_cache[8] = _createTextVNode()),
          _createElementVNode("rbe-footer-content", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "bottom-text" }, "\n          Recipients will be asked to create or log in with a Reedsy account.\n        ", -1)),
            _cache[4] || (_cache[4] = _createTextVNode()),
            _createVNode(_component_LoadingButton, {
              ref: "replaceAll",
              class: "button-text button-with-icon invite-button",
              type: "submit",
              loading: _ctx.isSendingRequest,
              disabled: !_ctx.inviteesEmails.length
            }, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "Invite", -1)),
                _createVNode(_component_VuiIconEnvelope)
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}