import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "hidden-until-md" }
const _hoisted_2 = { class: "flex-top" }
const _hoisted_3 = { class: "vui-radio-group" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiRadio = _resolveComponent("VuiRadio")!
  const _component_PlainModal = _resolveComponent("PlainModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_PlainModal, { id: _ctx.id }, {
    title: _withCtx(() => [
      _cache[2] || (_cache[2] = _createTextVNode("\n      Welcome to Reedsy Studio")),
      _createElementVNode("span", _hoisted_1, ", " + _toDisplayString(_ctx.firstName), 1),
      _cache[3] || (_cache[3] = _createTextVNode("\n      👋\n    "))
    ]),
    default: _withCtx(() => [
      _cache[9] || (_cache[9] = _createTextVNode()),
      _createElementVNode("rbe-body", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Where are you on your writing journey?", -1)),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, "Let us know so we can connect you with the right features and resources.", -1)),
        _cache[7] || (_cache[7] = _createTextVNode()),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableWritingStages, (writingStage) => {
            return (_openBlock(), _createBlock(_component_VuiRadio, {
              key: writingStage,
              ref_for: true,
              ref: writingStage,
              modelValue: _ctx.selection,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
              "radio-value": writingStage
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.WRITING_STAGE_TEXTS[writingStage]), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "radio-value"]))
          }), 128))
        ]),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _withDirectives((_openBlock(), _createElementBlock("rbe-tooltip", null, [
          _createElementVNode("button", {
            ref: "continueButton",
            class: "button button-text full-width reedsy-accented",
            disabled: !_ctx.hasSelection,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateWritingStage && _ctx.updateWritingStage(...args)))
          }, "\n          Get started\n        ", 8, _hoisted_4)
        ])), [
          [
            _directive_tooltip,
            _ctx.hasSelection ? undefined : 'Please select one option',
            void 0,
            { bottom: true }
          ]
        ])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}