import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "reedsy-accented accent-premium" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconExternal = _resolveComponent("VuiIconExternal")!

  return (_openBlock(), _createElementBlock("rbe-subscription-account-settings", _hoisted_1, [
    _createElementVNode("p", null, [
      (_ctx.$subscription.hasAnyPaidFeature)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.url,
            class: "button button-text button-with-icon reedsy-accented",
            target: "_blank"
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "\n          Subscription settings\n        ", -1)),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _createVNode(_component_VuiIconExternal)
          ], 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "button-text reedsy-accented",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$subscriptionModal.open && _ctx.$subscriptionModal.open(...args)))
          }, "\n        Go Premium\n      "))
    ])
  ]))
}