import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass([_ctx.networkClass, "button share-button button-text button-with-icon"]),
    target: "_blank",
    href: _ctx.url
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.networkName), 1),
    _cache[0] || (_cache[0] = _createTextVNode()),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.networkIcon), { class: "icon-white" }))
  ], 10, _hoisted_1))
}