import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconExternal = _resolveComponent("VuiIconExternal")!
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.book)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.bookEditorLink,
            class: "button button-text reedsy-accented continue-writing-button",
            target: "_blank"
          }, [
            _cache[0] || (_cache[0] = _createTextVNode("\n      Continue writing\n      ")),
            _createVNode(_component_VuiIconExternal)
          ], 8, _hoisted_1))
        : (_openBlock(), _createBlock(_component_SkeletonLoader, {
            key: 1,
            class: "button-loader"
          }))
    ]),
    _: 1
  }))
}